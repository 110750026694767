<template>
  <div>
    <v-row>
      <v-col cols="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          color="primary"
          @click="handleStoreRole()"
          >Novo</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="roles"
          :items-per-page="20"
          @click:row="handleStoreRole($event.id)"
        >
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <role-form ref="RoleForm" @store="select(searchForm.page)" />
  </div>
</template>

<script>
import RoleForm from "../components/roles/forms/RoleForm.vue";

export default {
  components: { RoleForm },

  data() {
    return {

      headers: [
        { text: "Perfis", value: "name" },
      ],

      roles: [],

      searchForm : {
        page : 1
      }
    };
  },

  created() {
    this.select();
  },

  methods: {
    select(page = 1) {
      
      this.searchForm.page = page

      this.$http.index("employee/role", this.searchForm).then((response) => {
        this.roles = response.roles
      });
    },

    handleSearchInput(text) {
    
    },

    handleStoreRole(id = null) {
      this.$refs.RoleForm.open(id);
    },
  },
};
</script>

<style>
</style>