export default {
  icon: ' mdi-clipboard-list-outline',
  text: 'Inventário de Estoque',
  permissions: [
    { name: 'stockAuditIndex', text: 'Visualizar Inventário de Estoque', value: 0 },
    { name: 'stockAuditCreateEdit', text: 'Cadastrar/Editar Inventário de Estoque', value: 0 },
    { name: 'stockAuditCancel', text: 'Cancelar Inventário de Estoque', value: 0 },
    { name: 'stockAuditAddProduct', text: 'Adicionar Produto ao Inventário de Estoque', value: 0 },
    { name: 'updateStockViaAudit', text: 'Atualizar Estoque via Inventario', value: 0 },
  ]
}