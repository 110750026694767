import accounts from './rules/accounts.js'
import billPayable from './rules/billPayable.js'
import billReceivable from './rules/billReceivable.js'
import brands from './rules/brands.js'
import categories from './rules/categories.js'
// import companies from './rules/companies.js'
import customers from './rules/customers.js'
import employees from './rules/employees.js'
import products from './rules/products.js'
import professionals from './rules/professionals.js'
import purchases from './rules/purchases.js'
import settings from './rules/settings.js'
import stockAudits from './rules/stockAudits.js'
import stockTransfers from './rules/stockTransfers.js'
import suppliers from './rules/suppliers.js'
import sales from './rules/sales.js'
import deliveries from './rules/deliveries.js'
import representativeBrand from './rules/representativeBrand.js'

const rolePermissions = {

  groups: [
    accounts,
    billPayable,
    billReceivable,
    brands,
    categories,
    // companies,
    customers,
    deliveries,
    employees,
    products,
    professionals,
    purchases,
    settings,
    stockAudits,
    stockTransfers,
    suppliers,
    sales,
    representativeBrand

  ],
}

export default rolePermissions