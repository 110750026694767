export default {
  icon: 'mdi-dolly',
  text: 'Transferência de Estoque',
  permissions: [
    { name: 'stockTransferIndex', text: 'Visualizar Transferências de Estoque', value: 0 },
    { name: 'stockTransferCreateEdit', text: 'Cadastrar/Editar Transferências de Estoque', value: 0 },
    { name: 'stockTransferCancel', text: 'Cancelar Transferência de Estoque', value: 0 },
    { name: 'stockTransferReceive', text: 'Receber Transferência de Estoque', value: 0 },

  ]

}