export default {
  icon: 'mdi-cogs',
  text: 'Configurações',
  permissions: [
    { name: 'companyIndex', text: 'Visualizar empresas', value: 0 },
    { name: 'companyCreateEdit', text: 'Cadastrar/Editar empresas', value: 0 },
    { name: 'permissionIndex', text: 'Visualizar permissões', value: 0 },
    { name: 'permissionCreateEdit', text: 'Cadastrar/Editar permissões', value: 0 },

  ]
}