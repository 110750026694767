export default {
  icon: ' mdi-truck',
  text: 'Logística',
  permissions: [
    { name: 'deliveriesIndex', text: 'Visualizar Entregas', value: 0 },

    { name: 'deliveryCreateEdit', text: 'Cadastrar/Editar Entregas', value: 0 },

    { name: 'printDelivery', text: 'Imprimir Romaneio', value: 0 },
    { name: 'updateToProcessing', text: 'Iniciar Processo', value: 0 },
    // { name: 'updateToInRoute', text: 'Colocar em rota', value: 0 },
    // { name: 'updateToDelivered', text: 'Marcar como Entregue', value: 0 },
    { name: 'deliveryCancel', text: 'Cancelar Entregas', value: 0 },
  ]
}