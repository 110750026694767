export default
  {
    icon: ' mdi-clipboard-text',
    text: 'Compras',
    permissions: [
      { name: 'purchaseDashboard', text: 'Dashboard compras', value: 0 },
      { name: 'purchasesIndex', text: 'Visualizar compras', value: 0 },
      { name: 'purchasesCreateEdit', text: 'Criar/Editar compras', value: 0 },
      { name: 'purchaseStoreBill', text: 'Lançar em contas a pagar', value: 0 },
      { name: 'purchaseStoreStock', text: 'Lançar o estoque', value: 0 },
      { name: 'purchaseCancel', text: 'Cancelar compras', value: 0 },
    ]
  }