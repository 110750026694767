<template>
  <v-dialog v-model="dialog" persistent max-width="700" scrollable>
    <v-card>
      <v-card-title>Novo Perfil</v-card-title>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="12">
            <app-text-field v-model="form.name" label="Descrição" />
          </v-col>
        </v-row>

        <h3 class="mt-10 mb-6">Permissões</h3>
        <!-- <v-row>
          <v-col cols="12">
            <v-switch
              :label="'contas a pg'"
              color="primary"
              hide-details
              v-model="permissionsForm.billPayableIndex"
              :true-value="1"
              :false-value="0"
            />

            <v-switch
              :label="'pg conta a pg'"
              color="primary"
              hide-details
              v-model="permissionsForm.billPayablePay"
              :true-value="1"
              :false-value="0"
            />
          </v-col>
        </v-row> -->
        <div v-for="(group, index) in rolesPermissions.groups" :key="index">
          <span class="subtitle-1">
            <v-icon left>{{ group.icon }}</v-icon
            >{{ group.text }}
          </span>
          <v-row class="mb-5">
            <v-col cols="12">
              <v-switch
                v-for="(permission, index) in group.permissions"
                :key="index"
                :label="permission.text"
                color="primary"
                hide-details
                v-model="permissionsForm[permission.name]"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <br />
        </div>
      </v-card-text>

      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rolesPermissions from "../../../enums/rolesPermissions";
export default {
  data() {
    return {
      dialog: false,

      id: null,

      form: {},

      baseForm: {
        name: null,
      },

      permissionsForm: {},

      // basePermissionsForm: {
      //   billPayableIndex: 0,
      //   billPayablePay: 0,
      // },

      rolesPermissions: rolesPermissions,
    };
  },

  computed: {
    isInsert() {
      return !this.id;
    },

    basePermissionsForm() {
      let basePermissions = {};

      for (let group of this.rolesPermissions.groups) {
        for (let permission of group.permissions) {
          basePermissions[permission.name] = 0;
        }
      }

      return basePermissions;
    },
  },

  created() {
    this.reset();
  },

  methods: {
    async open(id = null) {
      this.reset();

      if (id) {
        this.id = id;
        await this.showRole(id);
      }

      this.dialog = true;
    },

    reset() {
      this.id = null;
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.permissionsForm = JSON.parse(
        JSON.stringify(this.basePermissionsForm)
      );
    },

    async showRole(id) {
      await this.$http.show("employee/role", id).then((response) => {
        // console.log(response);
        this.form = response.role;
        this.setPermissions(response.role.permissions);
      });
    },

    setPermissions(permissions) {
      for (let permission of permissions) {
        this.permissionsForm[permission.name] = 1;
      }
    },

    getFormData() {
      let data = this.form;
      data.permissionsForm = this.permissionsForm;
      return data;
    },

    handleSave() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$http.store("employee/role", this.getFormData()).then((response) => {
        this.processSaved();
      });
    },

    update() {
      this.$http
        .update("employee/role", this.id, this.getFormData())
        .then((response) => {
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>
