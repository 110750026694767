export default {
  icon: ' mdi-account',
  text: 'Clientes',
  permissions: [
    { name: 'customersIndex', text: 'Visualizar Clientes', value: 0 },

    { name: 'customerCreateEdit', text: 'Cadastrar/Editar Clientes', value: 0 },

    { name: 'customerCreditAnalysis', text: 'Análise de Crédito', value: 0 },
    { name: 'customerDebtStatement', text: 'Extrato de Débito', value: 0 },
  ]
}