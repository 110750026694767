export default {
  icon: 'mdi-cash-plus',
  text: 'Contas a Receber',
  permissions: [
    { name: 'canViewBillReceivableDashboard', text: 'Dashboard contas a receber', value: 0 },
    { name: 'billReceivableIndex', text: 'Visualizar contas a receber', value: 0 },
    { name: 'billReceivableCreateEdit', text: 'Cadastrar/Editar contas a receber', value: 0 },
    { name: 'billReceivableCancel ', text: 'Cancelar contas a receber', value: 0 },
    { name: 'payBillReceivable', text: 'Receber contas', value: 0 },
  ]
}